var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"privacy_management",staticStyle:{"overflow":"hidden","padding":"5px 10px 10px 10px"}},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t("add_privacy.add_privacy"))+" ")]),(!_vm.isLoading)?_c('div',[_c('table',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("admin.title")))]),_c('th',[_vm._v(_vm._s(_vm.$t("admin.description")))]),_c('th',[_vm._v(_vm._s(_vm.$t("admin.edit")))])]),_c('tr',[_c('td',[(_vm.lang == 'ka')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.allPrivacy.titleKA)}}):_c('div',{domProps:{"innerHTML":_vm._s(_vm.allPrivacy.titleEN)}})]),_c('td',[(_vm.lang == 'ka')?_c('div',{ref:"descriptionKA",staticClass:"short_text",staticStyle:{"padding-left":"20px"}}):_c('div',{ref:"descriptionEN",staticClass:"short_text",staticStyle:{"padding-left":"20px"}})]),_c('td',[_c('b-button',{on:{"click":function($event){(_vm.modalShow = !_vm.modalShow),
                _vm.editPrivacy(
                  _vm.allPrivacy._id,
                  _vm.allPrivacy.descriptionKA,
                  _vm.allPrivacy.descriptionEN,
                  _vm.allPrivacy.titleKA,
                  _vm.allPrivacy.titleEN
                )}}},[_c('i',{staticClass:"fal fa-pen"})])],1)])])]):_c('div',[_c('b-skeleton-table',{attrs:{"rows":3,"columns":3,"table-props":{ bordered: true, striped: true }}})],1),_c('b-modal',{attrs:{"id":"modal-xl-2","size":"xl","hide-footer":""},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('div',{staticClass:"text_editor"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("admin.title"))+" (KA)")]),_c('vue-editor',{model:{value:(_vm.update.titleKA),callback:function ($$v) {_vm.$set(_vm.update, "titleKA", $$v)},expression:"update.titleKA"}}),_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("admin.title"))+" (EN)")]),_c('vue-editor',{model:{value:(_vm.update.titleEN),callback:function ($$v) {_vm.$set(_vm.update, "titleEN", $$v)},expression:"update.titleEN"}})],1),_c('div',{staticClass:"text_editor"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("admin.description"))+" (KA)")]),_c('vue-editor',{model:{value:(_vm.update.descriptionKA),callback:function ($$v) {_vm.$set(_vm.update, "descriptionKA", $$v)},expression:"update.descriptionKA"}}),_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("admin.description"))+" (EN)")]),_c('vue-editor',{model:{value:(_vm.update.descriptionEN),callback:function ($$v) {_vm.$set(_vm.update, "descriptionEN", $$v)},expression:"update.descriptionEN"}})],1),_c('button',{staticClass:"submit btn btn-primary",attrs:{"type":"submit"},on:{"click":function($event){return _vm.UpdatePrivacy()}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoading),expression:"!isLoading"}]},[_vm._v(_vm._s(_vm.$t("admin.edit")))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]},[_vm._v(" "+_vm._s(_vm.$t("login.loading"))+" "),_c('spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"text":"loading"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }